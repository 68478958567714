import React, { useEffect } from "react";
import { Views } from "../index";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import { selectCurrent } from "../../../store/ducks/sessions/selectors";
import { fetchCurrent } from "../../../store/ducks/sessions";
import Icon from "../../../styled/Icon";
import {
  faAlignCenter,
  faRetweet,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import Grid from "@material-ui/core/Grid";
import { getSessionHistoryParameters } from "../helpers";
import SessionSettings from "../SessionSettings";
import Header from "../../../components/Header";
import { getDateTime } from "../../../utils/datetime";
import CloseButton from "../styled/CloseButton";
import labels from "../../../utils/labels";
import { SessionParams } from "../../../store/ducks/sessions/types";
import { NumberSamplesListElement } from "../types";
import TimeLineData from "./TimeLineData";
import NumberSamplesSelect from "../components/NumberSamplesSelect";
import { ReportsButtons } from "../components/ReportsButtons";

interface Props {
  view: Views;
  sessionProfileParams: SessionParams;
  openSessionHistory: () => void;
  changeSessionProfileNumberSamples: (
    numberSamples: NumberSamplesListElement
  ) => void;
}

const SessionProfile = ({
  view,
  sessionProfileParams,
  openSessionHistory,
  changeSessionProfileNumberSamples,
}: Props) => {
  const dispatch = useAppDispatch();

  const session = useSelector(selectCurrent);

  useEffect(() => {
    if (view === Views.sessionProfile) {
      dispatch(fetchCurrent(sessionProfileParams));
    }
  }, [view, sessionProfileParams]);

  const sessionHistoryParameters = getSessionHistoryParameters(session);

  return (
    <>
      <CloseButton onClick={openSessionHistory} />
      <br />
      {session && (
        <>
          <Header level={2}>{labels.sessions.session}</Header>
          <NumberSamplesSelect
            value={sessionProfileParams.numberSamples}
            onChange={changeSessionProfileNumberSamples}
          />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              {session.userId && (
                <>
                  <label>
                    <b>
                      {labels.sessions.userId}: {session.userId}
                    </b>
                  </label>
                  <br />
                </>
              )}
              {session.startDateTime && (
                <>
                  <label>
                    {labels.sessions.start}:{" "}
                    {getDateTime(session.startDateTime)}
                  </label>
                  <br />
                </>
              )}
              {session.stopDateTime && (
                <>
                  <label>
                    {labels.sessions.stop}: {getDateTime(session.stopDateTime)}
                  </label>
                  <br />
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {session.classifierName && (
                <>
                  <Icon icon={faAlignCenter} size="lg" />
                  <label>
                    {labels.sessions.classifier}: {session.classifierName}
                  </label>
                  <br />
                </>
              )}
              {session.stateMachineName && (
                <>
                  <Icon icon={faRetweet} size="lg" />
                  <label>
                    {labels.sessions.stateMachine}: {session.stateMachineName}
                  </label>
                  <br />
                </>
              )}
              {session.experienceName && (
                <>
                  <Icon icon={faStar} size="lg" />
                  <label>
                    {labels.sessions.experience}: {session.experienceName}
                  </label>
                  <br />
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <SessionSettings
            session={session}
            sessionHistoryParameters={sessionHistoryParameters}
          />
          <br />
          <br />
          <br />
          <TimeLineData session={session} />
          <ReportsButtons session={session} />
        </>
      )}
    </>
  );
};

export default SessionProfile;
