import {
  Child,
  FilteringParams as ChildrenFilteringParams,
} from "../../../types/children";
import { Pagination } from "../../../types/pagination";

export interface User {
  userId: string;
  emailVerified: boolean;
  members: number;
  role?: string;
  email?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  birthday?: Date;
  street?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
  phone?: string;
  website?: string;
  fax?: string;
  gender?: boolean;
}

export interface FilteringParams {
  idFilter?: string;
  emailFilter?: string;
  roleFilter?: string;
}

export const DefaultPaginationParams = {
  pageIndex: 0,
  pageSize: 50,
  pageElements: 0,
  totalPages: 0,
  totalElements: 0,
};

export const DefaultFilteringParams = {
  idFilter: "",
  emailFilter: "",
  roleFilter: "",
};

export interface State {
  data: {
    current: User | null;
    list: Array<User> | null;
    children: Array<Child> | null;
  };
  loading: {
    current: boolean;
    list: boolean;
    children: boolean;
  };
  error: {
    current: string | null;
    list: string | null;
    children: string | null;
  };
  pagination: {
    list: Pagination;
    children: Pagination;
  };
  filtering: {
    list: FilteringParams;
    children: ChildrenFilteringParams;
  };
}
