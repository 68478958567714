import { Config } from "./types";

import antConfig from "./configs/ant";
import starrbaseConfig from "./configs/starrbase";
import developmentConfig from "./configs/development";
import productionConfig from "./configs/production";
import testConfig from "./configs/test";

const appConfig = (): Config => {
  let env;
  switch (window.location.host) {
    case "account-test.neuromore.com":
    case "127.0.0.1:3000":
    case "localhost:3000":
      env = "test";
      break;
    case "account.neuromore.com":
      env = "production";
      break;
    case "starrbase.myneurva.com":
      env = "starbase";
      break;
    case "account.eego-perform.com":
      env = "ant";
      break;
    default:
      env = "dev";
  }

  console.log(env);

  if (env === "production") {
    return productionConfig;
  }

  if (env === "test") {
    return testConfig;
  }

  if (env === "ant") {
    return antConfig;
  }

  if (env === "starrbase") {
    return starrbaseConfig;
  }

  return developmentConfig;
};

export default appConfig();
