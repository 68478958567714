import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";

import SessionHistory from "./SessionHistory";
import SessionProfile from "./SessionProfile";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import {
  selectIsCurrentLoading,
  selectIsGroupedStatisticLoading,
  selectIsListLoading,
  selectIsMonthlyStatisticLoading,
} from "../../store/ducks/sessions/selectors";
import UsersOverview from "./UsersOverview";
import labels from "../../utils/labels";
import { SessionHistoryParams } from "../../store/ducks/sessions/types";
import useProfileParams from "./hooks/useProfileParams";
import { numberSamplesListDefaultElement } from "./constants";

export enum Views {
  usersOverview = 0,
  sessionHistory = 1,
  sessionProfile = 2,
}

const Sessions = () => {
  const [view, setView] = useState<Views>(Views.usersOverview);
  const [sessionHistoryParams, setSessionHistoryParams] = useState<
    SessionHistoryParams
  >({});
  const {
    params: sessionProfileParams,
    changeSessionId: changeSessionProfileSessionId,
    changeNumberSamples: changeSessionProfileNumberSamples,
  } = useProfileParams();

  const isListLoading = useSelector(selectIsGroupedStatisticLoading);
  const isSessionLoading = useSelector(selectIsCurrentLoading);
  const isMonthlyStatisticLoading = useSelector(
    selectIsMonthlyStatisticLoading
  );
  const isSessionsListLoading = useSelector(selectIsListLoading);
  const isLoading =
    isListLoading ||
    isSessionLoading ||
    isMonthlyStatisticLoading ||
    isSessionsListLoading;

  return (
    <Loader isLoading={isLoading} text={labels.loading.loadingSessions}>
      <article>
        <SwipeableViews axis="x" index={view}>
          <UsersOverview
            view={view}
            openSessionHistory={() => setView(Views.sessionHistory)}
            setSessionHistoryParams={setSessionHistoryParams}
          />
          <SessionHistory
            view={view}
            userId={sessionHistoryParams.userId}
            supervisorId={sessionHistoryParams.supervisorId}
            openUsersOverview={() => setView(Views.usersOverview)}
            openSessionProfile={() => setView(Views.sessionProfile)}
            changeSessionProfileSessionId={changeSessionProfileSessionId}
          />
          <SessionProfile
            view={view}
            sessionProfileParams={sessionProfileParams}
            changeSessionProfileNumberSamples={
              changeSessionProfileNumberSamples
            }
            openSessionHistory={() => {
              setView(Views.sessionHistory);
              changeSessionProfileNumberSamples(
                numberSamplesListDefaultElement
              );
            }}
          />
        </SwipeableViews>
      </article>
    </Loader>
  );
};

export default Sessions;
