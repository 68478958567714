import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import {
  selectList,
  selectListPagination,
  selectMonthlyStatisticList,
} from "../../store/ducks/sessions/selectors";
import {
  fetchMonthlyStatisticList,
  fetchList,
  delete_,
} from "../../store/ducks/sessions";
import { DefaultPaginationParams } from "../../store/ducks/sessions/types";
import Wrapper from "../../components/Tabs/styled/Wrapper";
import Button from "../../styled/Button";
import Icon from "../../styled/Icon";
import {
  faTrashAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import TableWrapper from "../../styled/Table/TableWrapper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import labels from "../../utils/labels";
import TableBody from "@material-ui/core/TableBody";
import {
  addTimezoneOffset,
  convertMilliSecondsToTimeString,
  getDate,
  getDateTime,
} from "../../utils/datetime";
import SpeedDials from "../../components/SpeedDial";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import LineGraph from "../../components/LineGraph";
import { Views } from "./index";
import DeleteConfirmationDialog from "../../components/ConfirmationDialog/DeleteConfirmationDialog";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";
import CLink from "../../styled/CSVLink";
import Grid from "../../styled/Grid";
import { sessionHistoryOptions } from "./constants";
import CloseButton from "./styled/CloseButton";

interface Props {
  openUsersOverview: () => void;
  openSessionProfile: () => void;
  userId: string | undefined;
  supervisorId: string | undefined;
  changeSessionProfileSessionId: (sessionId: string) => void;
  view: Views;
}

const SessionHistory = ({
  userId,
  supervisorId,
  openUsersOverview,
  openSessionProfile,
  changeSessionProfileSessionId,
  view,
}: Props) => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!;
  const sessions = useSelector(selectList);
  const monthlyStatistic = useSelector(selectMonthlyStatisticList);

  const pagination = useSelector(selectListPagination);

  const showDeleteButton = hasRule(auth, RULES.REST_deleteDataChunk);
  const showShowButton = hasRule(auth, RULES.REST_getDataChunk);

  useEffect(() => {
    if (view === Views.sessionHistory) {
      dispatch(
        fetchMonthlyStatisticList({
          userId,
          supervisorId: supervisorId,
        })
      );

      dispatch(
        fetchList({
          userId,
          pagination: DefaultPaginationParams,
        })
      );
    }
  }, [view]);

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      fetchList({
        userId,
        pagination: {
          pageIndex: newPage,
        },
      })
    );
  };

  const handleChangeRowsPerPage = (event: React.SyntheticEvent) => {
    const pageSize = parseInt((event.target as HTMLSelectElement).value, 10);
    dispatch(
      fetchList({
        userId,
        pagination: {
          pageSize,
          pageIndex: 0,
        },
      })
    );
  };

  const showSession = (sessionId: string) => {
    changeSessionProfileSessionId(sessionId);
    openSessionProfile();
  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const deleteSession = (sessionId: string) => {
    dispatch(delete_(sessionId));
  };

  const actions = (sessionId: string) => {
    let result = [];
    if (showShowButton) {
      result.push({
        icon: <Icon icon={faExternalLinkAlt} default="true" size="lg" />,
        name: labels.button.show,
        handler: () => showSession(sessionId),
      });
    }
    if (showDeleteButton) {
      result.push({
        icon: <Icon icon={faTrashAlt} default="true" red="true" size="lg" />,
        name: labels.button.delete,
        handler: () => setDeleteConfirmOpen(true),
      });
    }
    return result;
  };

  const getTimeLineData = () => {
    if (!monthlyStatistic) {
      return;
    }

    return monthlyStatistic.reduce(
      (out, statistic) => {
        out.data.push(statistic.sessions);
        out.labels.push(getDate(addTimezoneOffset(statistic.sessionTime)));
        return out;
      },
      { labels: [] as string[], data: [] as number[] }
    );
  };

  const timeLineData = getTimeLineData();

  const historyOfAllUsers = !userId;

  return (
    <AuthWrapper>
      <Wrapper>
        <CloseButton onClick={openUsersOverview} />
        {timeLineData && (
          <LineGraph
            label={labels.sessions.last30Sessions}
            labels={timeLineData.labels}
            data={timeLineData.data}
            options={sessionHistoryOptions}
          />
        )}
        <h3 style={{ color: "black" }}>Full List</h3>

        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                {historyOfAllUsers && (
                  <TableCell>{labels.sessions.user}</TableCell>
                )}
                <TableCell>{labels.sessions.provider}</TableCell>
                <TableCell>{labels.sessions.time}</TableCell>
                <TableCell>{labels.sessions.duration}</TableCell>
                <TableCell>{labels.sessions.experience}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions &&
                sessions.map((session, index) => (
                  <TableRow key={index}>
                    {historyOfAllUsers && (
                      <TableCell>
                        {session.user
                          ? `${session.user.firstname ?? ""} ${
                              session.user.lastname ?? ""
                            }`
                          : ""}
                      </TableCell>
                    )}
                    <TableCell>
                      {session.provider
                        ? `${session.provider.firstname ?? ""} ${
                            session.provider.lastname ?? ""
                          }`
                        : ""}
                    </TableCell>
                    <TableCell>{getDateTime(session.sessionTime)}</TableCell>
                    <TableCell>
                      {convertMilliSecondsToTimeString(session.duration)}
                    </TableCell>
                    <TableCell>{session.sessionName}</TableCell>
                    <TableCell align="right">
                      <SpeedDials actions={actions(session.sessionId)} />
                      <DeleteConfirmationDialog
                        open={deleteConfirmOpen}
                        setOpen={setDeleteConfirmOpen}
                        onConfirm={() => deleteSession(session.sessionId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={pagination.totalElements || 0}
                  rowsPerPage={pagination.pageSize || 0}
                  page={pagination.pageIndex || 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableWrapper>
        <br />
        <Grid container end="true">
          {sessions && (
            <CLink data={sessions}>
              <Button blue="true">
                <GetAppIcon /> CSV
              </Button>
            </CLink>
          )}
        </Grid>
      </Wrapper>
    </AuthWrapper>
  );
};

export default SessionHistory;
