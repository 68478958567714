import { Config, ExperienceArtifacts } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "http://127.0.0.1:3000",
  serverUrl: "https://backend-test.neuromore.com",
  // for local builds
  // serverUrl: "http://localhost:8080/neuromore-server",
  registrationUrl:
    "https://account.neuromore.com/#/purchase?state=start&productId=community",
  downloads: [
    {
      label: messages.software,
      items: [
        {
          label: "neuromore Studio (macOS)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/macos/neuromoreInstaller.dmg",
        },
        {
          label: "neuromore Studio (Windows)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/windows/neuromoreInstaller.exe",
        },
      ],
    },
  ],
  checkout: {
    recaptchaKey: "6LfLVSUTAAAAAByd6rRJP10Tdq97Mrznd0Be5p7R", // must be 6LdtOQcTAAAAAI0hOjvKSXW6AYJ2m4Ji0bAnazXZ
    token: "ZmYxMjc2ZGEtYWE3My00MTIyLTgzOWMtM2UwNjAyN2E5MzFl",
    stripeApiKey: "pk_test_bS4tkuLEotc4jqLuWNBVkrA0",
  },
  filesystem: {
    aws: {
      endpoint: "https://experience-assets-test.s3.amazonaws.com",
      AWSAccessKeyId: "AKIAIBSMRMR3X7ZERUBA",
      acl: "private",
      policy:
        "eyJleHBpcmF0aW9uIjoiMjAyNS0wMS0wMVQwMDowMDowMFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJleHBlcmllbmNlLWFzc2V0cy10ZXN0In0sWyJzdGFydHMtd2l0aCIsICIka2V5IiwgIm1ldGEvIl0seyJzdWNjZXNzX2FjdGlvbl9yZWRpcmVjdCI6ICJodHRwOi8vbG9jYWxob3N0LyJ9LHsiYWNsIjoicHJpdmF0ZSJ9LFsic3RhcnRzLXdpdGgiLCAiJENvbnRlbnQtVHlwZSIsICJpbWFnZS8iXSxbICJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDAsIDEwNDg1NzYwXV19",
      signature: "gidlcR/RxzvylMo2puJz9uUAD+s=",
      redirect: "http://localhost/",
      icons: "meta/icons",
      backgrounds: "meta/backgrounds",
    },
    files: {
      templates: {
        classifier: "3bb0ff99-aeca-4416-a8bd-6899798ea4aa",
        stateMachine: {
          [ExperienceArtifacts.Audio]: "7dc2b400-dfb1-486d-874a-a1ad95228ba7",
          [ExperienceArtifacts.Video]: "d235d708-e446-40f3-8b00-aa264d397585",
          [ExperienceArtifacts.AudioVideo]:
            "c507096f-d25d-4445-bc97-2f7b96d74a87",
        },
      },
    },
  },
  branding: {
    id: "neuromore",
    helmet: {
      title: "neuromore User Portal",
      description: "neuromore account management",
    },
    logo: {
      alt: "Neuromore logo",
      link: "https://www.neuromore.com",
    },
    companyName: "neuromore",
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
};

export default config;
