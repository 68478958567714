import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Form, FormSpy } from "react-final-form";
import { useLocation } from "react-router-dom";

// TODO move it to global
import Button from "../../pages/Login/styled/Button";
import Input from "../../components/Form/Input";
import { User } from "../../store/ducks/users/types";
import { Auth } from "../../store/ducks/auth/types";
import Members from "../../components/Members";

import {
  selectChildren,
  selectChildrenPagination,
} from "../../store/ducks/users/selectors";
import { fetchChildren, update } from "../../store/ducks/users";
import SaveIcon from "@material-ui/icons/Save";
import DatePicker from "../../components/Form/DatePicker";
import Select from "../../components/Form/Select";
import { genderOptions } from "./constants";
import { mapRoleStringRepresentationToID } from "./utils";
import { useAppDispatch } from "../../store";
import { FORM_ERROR } from "final-form";
import { removeTimezoneOffset } from "../../utils/datetime";
import SelectCountry from "../../components/SelectCountry";
import { InitMembers } from "../../components/Members/types";
import labels from "../../utils/labels";
import { email } from "../../utils/form";
import { ROUTES_PATHS } from "../../Routes";

interface Props {
  user: User;
  fields: any;
  auth: Auth;
  showUpdateButton: boolean;
  showMembers: boolean;
}

const ProfileTab = ({
  user,
  fields,
  auth,
  showUpdateButton,
  showMembers,
}: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const onSubmit = (values: User) => {
    let data = {
      ...values,
      ...members,
      birthday: values.birthday
        ? removeTimezoneOffset(values.birthday)
        : undefined,
      // we don't have role field in the UI, and it broke the profile update if provided
      role: undefined,
    };

    return new Promise((resolve, reject) => {
      dispatch(update(data, { resolve, reject }));
    })
      .then(() => {
        setMembers(InitMembers);
        // TODO: reset password and confirmed fields
      })
      .catch((error) => {
        return {
          [FORM_ERROR]: error,
        };
      });
  };

  const [members, setMembers] = useState(InitMembers);

  const getMembers = () => members;

  return (
    <>
      <Form
        initialValues={user!}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.firstname} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.middlename} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.lastname} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.email} validation={email} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Select options={genderOptions} {...fields.gender} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <DatePicker {...fields.birthday} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.street} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.city} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.zip} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.state} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <SelectCountry {...fields.country} />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.phone} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.fax} />
              </Grid>

              <Grid item xs={6} md={3} lg={2}>
                <Input {...fields.website} />
              </Grid>

              {location.pathname !== `/${ROUTES_PATHS.profile}` && (
                <Grid item xs={6} md={3} lg={2}>
                  <Input {...fields.role} disabled />
                </Grid>
              )}
            </Grid>

            {showMembers && (
              <>
                <Members
                  id={auth.userId}
                  selectChildren={selectChildren}
                  selectChildrenPagination={selectChildrenPagination}
                  fetchChildren={fetchChildren}
                  getMembers={getMembers}
                  setMembers={setMembers}
                />
              </>
            )}
            <br />
            <br />
            {showUpdateButton && (
              <FormSpy subscription={{ dirty: true }}>
                {(props) =>
                  props.dirty ||
                  members.appendChildIds.length ||
                  members.removeChildIds.length ? (
                    <Button start="true" blue="true" type="submit">
                      <SaveIcon />
                      {labels.profile.update}
                    </Button>
                  ) : (
                    ""
                  )
                }
              </FormSpy>
            )}
          </form>
        )}
      />
    </>
  );
};

export default ProfileTab;
