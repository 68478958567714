import { theme } from "../../styled";

export const defaultHeight = 50;

export const defaultLineGraphProperties = {
  fill: true,
  backgroundColor: theme.color.blue,
  lineTension: 0.1,
  borderColor: theme.color.blue,
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: theme.color.blue,
  pointBackgroundColor: "#fff",
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: "rgba(75,192,192,1)",
  pointHoverBorderColor: "rgba(220,220,220,1)",
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
  bezierCurve: true,
};

export const defaultOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
    ],
  },
};
