import { Config, ExperienceArtifacts } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "https://account-test.neuromore.com",
  serverUrl: "https://backend-test.neuromore.com",
  registrationUrl:
    "https://account.neuromore.com/#/purchase?state=start&productId=community",
  downloads: [
    {
      label: messages.software,
      items: [
        {
          label: "neuromore Studio (macOS)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/macos/neuromoreInstaller.dmg",
        },
        {
          label: "neuromore Studio (Windows)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/windows/neuromoreInstaller.exe",
        },
      ],
    },
  ],
  checkout: {
    recaptchaKey: "6LfLVSUTAAAAAByd6rRJP10Tdq97Mrznd0Be5p7R",
    token: "ZmYxMjc2ZGEtYWE3My00MTIyLTgzOWMtM2UwNjAyN2E5MzFl",
    stripeApiKey: "pk_test_bS4tkuLEotc4jqLuWNBVkrA0",
  },
  filesystem: {
    aws: {
      endpoint: "https://experience-assets-test.s3.amazonaws.com",
      AWSAccessKeyId: "AKIAIBSMRMR3X7ZERUBA",
      acl: "private",
      policy:
        "eyJleHBpcmF0aW9uIjoiMjAyNS0wMS0wMVQwMDowMDowMFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJleHBlcmllbmNlLWFzc2V0cy10ZXN0In0sWyJzdGFydHMtd2l0aCIsICIka2V5IiwgIm1ldGEvIl0seyJzdWNjZXNzX2FjdGlvbl9yZWRpcmVjdCI6ICJodHRwOi8vbG9jYWxob3N0LyJ9LHsiYWNsIjoicHJpdmF0ZSJ9LFsic3RhcnRzLXdpdGgiLCAiJENvbnRlbnQtVHlwZSIsICJpbWFnZS8iXSxbICJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDAsIDEwNDg1NzYwXV19",
      signature: "gidlcR/RxzvylMo2puJz9uUAD+s=",
      redirect: "http://localhost/",
      icons: "meta/icons",
      backgrounds: "meta/backgrounds",
    },
    files: {
      templates: {
        classifier: "d0150b40-9635-4828-9ba1-66b821ab8d3d",
        stateMachine: {
          [ExperienceArtifacts.Audio]: "60066938-ee58-4bde-a794-f5bebce74823",
          [ExperienceArtifacts.Video]: "472b23d1-ee3e-4577-b0c3-5541e09da65b",
          [ExperienceArtifacts.AudioVideo]:
            "b5f1bc51-f01d-4309-986a-05a87b31f05c",
        },
      },
    },
  },
  branding: {
    id: "neuromore",
    helmet: {
      title: "neuromore User Portal",
      description: "neuromore account management",
    },
    logo: {
      alt: "Neuromore logo",
      link: "https://www.neuromore.com",
    },
    companyName: "neuromore",
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
};

export default config;
