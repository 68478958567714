export default {
  profile: {
    create: "Create User",
    update: "Update Profile",
    updatePassword: "Update Password",
    userId: "User ID",
    firstname: "Firstname",
    lastname: "Lastname",
    middlename: "Middlename",
    email: "Email",
    password: "Password",
    confirmed: "Confirm",
    gender: "Gender",
    birthday: "Birthday",
    street: "Street",
    zip: "ZIP",
    state: "State",
    country: "Country",
    phone: "Phone",
    fax: "Fax",
    website: "Website",
    role: "Role",
    removeChildIds: "Remove Child",
    appendChildIds: "Add Member",
    appendParentIds: "Parent",
    city: "City",
    members: "#Members",
    profile: "Profile",
    account: "Account",
    addMember: "Add to members",
    removeMember: "Remove from members",
    addUser: "Add User",
    addCompany: "Add Company",
    addGroup: "Add Group",
    type: "Parent is a",
    parentId: "Parent ID",
  },
  company: {
    create: "Create Company",
    companyId: "Company ID",
    company: "Company Name",
    street: "Street",
    zip: "ZIP",
    city: "City",
    state: "State",
    country: "Country",
    phone: "Phone",
    fax: "Fax",
    website: "Website",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    appendParentIds: "Parent",
    name: "Name",
    members: "#Members",
  },
  group: {
    create: "Create Group",
    groupId: "Group ID",
    name: "Group Name",
    appendParentIds: "Parent",
    groupName: "Name",
    members: "#Members",
  },
  logs: {
    userId: "User",
    logLevel: "Level",
    startDateTime: "Start Date",
    endDateTime: "Stop Date",
    emitterName: "Emitter",
    systemName: "System",
    date: "Date",
    time: "Time",
    user: "User ID",
    message: "Message",
    level: "Log Level",
    address: "IP Address",
  },
  events: {
    userId: "User",
    country: "Country",
    city: "City",
    type: "Type",
    emitterName: "Emitter",
    systemName: "System",
    startDateTime: "Start Date",
    endDateTime: "Stop Date",
    date: "Date",
    time: "Time",
    id: "User ID",
  },
  sessions: {
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    sessions: "Total Sessions",
    sessionLength: "Total Duration",
    avgTime: "Average Duration",
    sessionName: "Recent Session",
    sessionTime: "Recent Time",
    user: "User",
    provider: "Clinician/Operator",
    time: "Time",
    duration: "Duration",
    last30Sessions: "#Sessions (last 30 days)",
    userId: "User ID",
    start: "Start",
    stop: "Stop",
    classifier: "Classifier",
    stateMachine: "State Machine",
    experience: "Experience",
    times: "Time [s]",
    globalStats: "Global Stats",
    sessionStats: "Session Stats",
    parameter: "Parameter",
    preSession: "Pre-Session",
    postSession: "Post-Session",
    showHistory: "Show History",
    showCompleteHistory: "Show Complete History",
    downloadReport: "Download Report",
    saveUMDFile: "Download RAW Channel Data",
    rendered: "Rendered",
    recorded: "Recorded",
    samples: "Samples",
    sampleRate: "Sample-Rate",
    session: "Session",
    maxRenderSamples: "Maximum Render Samples",
    timeInSeconds: "Time [s]",
    downloadReportAndRAWChannelsData: "Download All Session Data",
    onlineReprtingProhibitionWarning:
      "Online Reporting is not available for your account. To enjoy this feature, please upgrade to one of our ",
  },
  errors: {
    access: "You do not have access to this page.",
  },
  deleteConfirmationDialog: {
    title: "Would you really like to delete?",
    content: "This operation cannot be undone and all data will be deleted.",
  },
  files: {
    name: "Name",
    country: "Country",
    language: "Language",
    devices: "Devices",
    priority: "Priority",
    sortKey: "Key",
    color: "Color",
    audioUrl: "Audio URL",
    videoUrl: "Video URL",
    durationMin: "Duration [min]",
    duration: "Duration [s]",
    price: "Price",
    releaseDateTime: "Release Date",
    credits: "Price",
    filename: "Enter File Name",
    createExperience: "Create Audio Experience",
    selectItem: "Please select an item.",
    priceNeuronium: "Price [neuronium]",
    tagType: "Type",
    tagValue: "Name",
  },
  auth: {
    id: "User ID or Email",
    password: "Password",
    comfirm: "Comfirm",
    email: "Email",
    forgotPassword: "Forgot password?",
    gotIt: "Got it!",
    resetPassword: "Password Reset",
    resetPasswordDescription:
      "Enter the email address that you used to register. We'll send you an email with a link to reset your password.",
    setNewPassword: "Set New Password",
    createAccount: "Creating account.",
    signUp: "Sign Up",
    subscribeNews: "Subscribe to our newsletter",
  },
  button: {
    create: "Create",
    search: "Search",
    save: "save",
    csv: "CSV",
    login: "Log in",
    register: "Register",
    emailMe: "Email Me",
    cancel: "Cancel",
    setPassword: "Set Password",
    show: "Show",
    delete: "Delete",
    signUp: "Sign Up",
  },
  loading: {
    common: "loading.",
    data: "loading data.",
    logging: "logging in.",
    user: "loading user.",
    company: "loading company.",
    group: "loading group.",
    sendingEmail: "sending email.",
    loadingSessions: "loading sessions.",
  },
};
