import { Config } from "../../types";
import {
  PerformStudioLabel,
  PerformLabel,
  PerformConverterLabel,
  MiniLabel,
  WaveguardLabel,
} from "./components";
import { messages } from "../../messages";
import { Role } from "../../../types";

const config: Config = {
  url: "https://account.eego-perform.com",
  serverUrl: "https://backend.eego-perform.com",
  registrationUrl: "",
  downloads: [
    {
      label: messages.software,
      items: [
        {
          label: PerformStudioLabel,
          link:
            "https://github.com/neuromore/studio/releases/download/1.4.9/EPStudio.zip",
        },
        {
          label: PerformConverterLabel,
          link:
            "https://eego-perform.com/download/eego_perform_edf_converter.zip",
        },
      ],
    },
    {
      label: messages.manual,
      items: [
        {
          label: PerformLabel,
          link: "https://eego-perform.com/download/UserManual_eego_perform.pdf",
        },
        {
          label: MiniLabel,
          link: "https://eego-perform.com/download/UserManual_eego_mini.pdf",
        },
        {
          label: WaveguardLabel,
          link:
            "https://eego-perform.com/download/UserManual_waveguard_cap.pdf",
        },
      ],
    },
    {
      label: messages.licenses,
      items: [
        {
          label: "License",
          link: "https://assets.eego-perform.com/license/license.txt",
        },
        {
          label: "Terms and Conditions",
          link:
            "https://assets.eego-perform.com/license/terms-and-conditions.txt",
        },
        {
          label: "Privacy Policy",
          link: "https://assets.eego-perform.com/license/privacy-policy.txt",
        },
      ],
    },
  ],
  branding: {
    id: "ant",
    helmet: {
      title: "eego perform User Portal",
      description: "eego perform account management",
    },
    logo: {
      alt: "eego perform logo",
      link: "https://eego-perform.com",
    },
    companyName: "eego perform",
  },
  sessions: {
    hideDataIfChannelNameStartsWith: "eeg-",
    hideMaxRenderSamplesSelection: true,
    hideSingleFileDownloadButtons: true,
    hideRenderedRecordedSamplesTable: true,
  },
  CRMUsers: {
    hideMembers: true,
  },
  userRoles: [
    Role.ClinicAdmin,
    Role.ClinicClinician,
    Role.ClinicOperator,
    Role.ClinicPatient,
  ],
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
};

export default config;
