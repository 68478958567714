import { Config, ExperienceArtifacts } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "https://account.neuromore.com",
  serverUrl: "https://backend.neuromore.com",
  registrationUrl:
    "https://account.neuromore.com/#/purchase?state=start&productId=community",
  downloads: [
    {
      label: messages.software,
      items: [
        {
          label: "neuromore Studio (macOS)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/macos/neuromoreInstaller.dmg",
        },
        {
          label: "neuromore Studio (Windows)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/windows/neuromoreInstaller.exe",
        },
      ],
    },
  ],
  checkout: {
    recaptchaKey: "6LcSeAcTAAAAAL8kPRWZrjpS6yf227UuiYsJMJxn",
    token: "ZmYxMjc2ZGEtYWE3My00MTIyLTgzOWMtM2UwNjAyN2E5MzFl",
    stripeApiKey: "pk_live_UOYwf3O7jefxZ8c2qCf4KKru",
  },
  filesystem: {
    aws: {
      endpoint: "https://experience-assets.s3.amazonaws.com",
      AWSAccessKeyId: "AKIAI6BQO5DDXQOY22TA",
      acl: "private",
      policy:
        "eyJleHBpcmF0aW9uIjoiMjAyNS0wMS0wMVQwMDowMDowMFoiLCJjb25kaXRpb25zIjpbeyJidWNrZXQiOiJleHBlcmllbmNlLWFzc2V0cyJ9LFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJtZXRhLyJdLHsic3VjY2Vzc19hY3Rpb25fcmVkaXJlY3QiOiAiaHR0cDovL2xvY2FsaG9zdC8ifSx7ImFjbCI6InByaXZhdGUifSxbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiaW1hZ2UvIl0sWyAiY29udGVudC1sZW5ndGgtcmFuZ2UiLCAwLCAxMDQ4NTc2MF1dfQ==",
      signature: "MmLQEz6KuHIKfuzsrlYc+L1L4bA=",
      redirect: "http://localhost/",
      icons: "meta/icons",
      backgrounds: "meta/backgrounds",
    },
    files: {
      templates: {
        classifier: "3075816e-68c6-45d0-9a6e-b6531e33b023",
        stateMachine: {
          [ExperienceArtifacts.Audio]: "b77ee679-cb9e-406a-b5bd-1aec5389c2f2",
          [ExperienceArtifacts.Video]: "9f5a5c4d-b4a8-467e-a52f-559e1b10317a",
          [ExperienceArtifacts.AudioVideo]:
            "3d4d5cd2-bbce-4374-9dea-a7cd0b961606",
        },
      },
    },
  },
  branding: {
    id: "neuromore",
    helmet: {
      title: "neuromore User Portal",
      description: "neuromore account management",
    },
    logo: {
      alt: "Neuromore logo",
      link: "https://www.neuromore.com",
    },
    companyName: "neuromore",
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
};

export default config;
