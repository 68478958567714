import { saveAs } from "file-saver";
import { SessionDetails } from "../../store/ducks/sessions/types";
import config from "config";
import { TimeLineDataItem } from "./types";
import { delay } from "../../utils/helpers";

export const getSessionHistoryParameters = (session: SessionDetails | null) => {
  if (!session || !session.parameters) {
    return;
  }

  let table: any = {};

  if (session.parameters.previousSessionParameters) {
    session.parameters.previousSessionParameters.forEach((parameter) => {
      if (!table[parameter.name]) {
        table[parameter.name] = {};
      }

      table[parameter.name]["previous"] = parameter.parameter;
    });
  }

  if (session.parameters.currentSessionParameters) {
    session.parameters.currentSessionParameters.forEach((parameter) => {
      if (!table[parameter.name]) {
        table[parameter.name] = {};
      }

      table[parameter.name]["current"] = parameter.parameter;
    });
  }

  return Object.keys(table).map((key) => ({
    key: key,
    previous: table[key]["previous"],
    current: table[key]["current"],
  }));
};

export const getTimeLineData = (session: SessionDetails | null) => {
  if (!session || !session.channels) {
    return null;
  }

  return session.channels.reduce<Array<TimeLineDataItem>>((out, channel) => {
    if (!channel.data || !channel.data.length) {
      return out;
    }

    const prefix = config.sessions?.hideDataIfChannelNameStartsWith;
    if (prefix && channel.channelName.startsWith(prefix)) {
      return out;
    }

    const result: TimeLineDataItem = {
      data: [],
      labels: [],
      name: channel.channelName,
      dataUrl: channel.dataUrl,
      dataSamples: channel.dataSamples,
      totalSamples: channel.totalSamples,
      sampleRate: `${channel.sampleRate} Hz`,
      dataSampleRate: `${
        channel.dataSampleRate
          ? channel.dataSampleRate.toFixed(2).replace(".00", "")
          : 0
      } Hz`,
    };

    channel.data.forEach((item, i) => {
      result.data.push(Number.parseFloat(item[1].toFixed(3)));
      result.labels.push(
        channel.data && i % Math.round(channel.data.length / 20) === 0
          ? item[0].toFixed(1)
          : ""
      );
    });

    out.push(result);
    return out;
  }, []);
};

export const download = async (url: string) => {
  const a = document.createElement("a");
  a.download = "";
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();

  await delay(100);
  a.remove();
};

export const downloadReport = (session: SessionDetails | null) => {
  if (!session) return;

  const blob = new Blob([JSON.stringify(session, undefined, 2)], {
    type: "application/json;charset=utf-8",
  });

  let filename = "user-session-raw-data";
  if (session.userId) filename += `-${session.userId}`;

  saveAs(blob, `${filename}.json`);
};

export const downloadReportAndRAWChannelsData = async (
  session: SessionDetails | null
) => {
  if (!session) return;

  for (let i = 0; i < session.channels.length; i++) {
    const channel = session.channels[i];
    if (!channel.dataUrl) continue;

    await delay(i * 1000);
    download(channel.dataUrl);
  }

  downloadReport(session);
};
